import React, { useState } from "react";
import "./Nav.css";
import { useAnimation } from "framer-motion";
import HemburgerMenu from "./HemburgerMenu/HemburgerMenu";
import { Link } from "react-router-dom";
import { getAnalytics, logEvent} from "firebase/analytics";


// function gtag_report_conversion(url) {
// 	const callback = function () {
// 	  if (typeof url !== 'undefined') {
// 		window.location = url;
// 	  }
// 	};
// 	window.gtag('event', 'conversion', {
// 	  'send_to': 'AW-11082418360/cc2VCJ3v_qAaELiRwaQp',
// 	  'event_callback': callback
// 	});
// 	return false;
//   }


function gtag_report_conversion(url) {
	console.log('gtag_report_conversion called'); // Debugging line
  
	const callback = function () {
	  if (typeof url !== 'undefined') {
		console.log('Redirecting to:', url); // Debugging line
		window.location = url; // Redirect only after the event is sent
	  }
	};
  
	if (typeof window.gtag === 'function') {
	  window.gtag('event', 'conversion', {
		'send_to': 'AW-11082418360/cc2VCJ3v_qAaELiRwaQp',
		'event_callback': callback // Use event_callback to delay the redirect
	  });
	  console.log('gtag event fired'); // Debugging line
	  return false; // Prevent default link behavior
	} else {
	  console.error('gtag is not available'); // Debugging line
	  // Fallback: Redirect even if gtag fails
	  if (typeof url !== 'undefined') {
		window.location = url;
	  }
	}
  }


export default function Nav({ modalHandler }) {

	const analytics = getAnalytics();

	const hemburgerMenuBar = useAnimation();
	const navSlideropen = () => {
		const body = document.querySelector("body");
		body.classList.add("stopScroll");
		hemburgerMenuBar.start({
			x: 0,
		});
	};
	const handleWhatsapp = () => {
		gtag_report_conversion('https://wa.me/971568819394');
		// window.open("https://wa.me/971568819394?text=Hi%20", "_blank");
		logEvent(analytics,"Clicked on Chat with us: Studio Page")
	};
	return (
		<>
			<HemburgerMenu modalHandler={modalHandler} animation={hemburgerMenuBar} />
			<div className='nav d-flex align-items-center justify-content-between px-md-2 px-lg-5 px-3 py-3 py-sm-0'>
				<Link to='/'>
				<div className='navBrand'>
					<img
						src='assets/AY_logo.svg'
						alt=''
						className='img-fluid webLogo'
					/>
					<img
						src='assets/Ay-Mobile.svg'
						alt=''
						className='img-fluid mblLogo'
					/>
				</div>
				</Link>
				

				<div className='d-flex align-items-center'>

					<div className='contact me-3 d-none d-md-block' style={{cursor:"pointer"}}>
						<div
							onClick={handleWhatsapp}
							className='d-flex align-items-center '>
							<span className='whatsapp me-2'>
								<img
									width="35px"
									height="32px"
									src='assets/whatsAppIcon.svg'
									alt=''
									className='img-fluid ms-1'
								/>
							</span>
							<span className="ChatWithUs">
								Chat with us
							</span>

						</div>

					</div>
					
					{/* <div
						className='joinToday'
						style={{ cursor: "pointer" }}
						onClick={() => modalHandler(true)}>
						Join Today
					</div> */}
					<div
						style={{ cursor: "pointer" }}
						className='d-block d-sm-none ms-3'
						onClick={navSlideropen}>
						<img
							src='assets/hamburger.svg'
							alt=''
							className='img-fluid'
						/>
					</div>
				</div>
			</div>
		</>
	);
}
